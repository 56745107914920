import React from "react"
import "../../works/perle.css"
import Footer from "../../../components/footer"
import WorkHeader from "../../../components/workHeader"
import perle from "../../../../static/work_headers/perle.png"
import Dict from "../../../langs/dict"
import WorkAbout from "../../../components/workAbout"
import Perk from "../../../components/perks"
import perle1 from "../../../../static/works_perks/perle_1.png"
import perle3 from "../../../../static/works_perks/perle_2.png"
import perle2 from "../../../../static/works_perks/perle_3.png"
import Contact from "../../../components/contact"
import logo from "../../../../static/logos/perle_logo.png"
import Layout from "../../../components/layoutWorks"

export default () => {
  const lang = "fr"
  const dict = new Dict(lang)
  return (
    <Layout
      seoTitle="Application Web - perle.io"
      seoDescription="L'idée de l'application perle est de créer un journal intime en ligne épuré. La saisie est limitée au texte et chaque jour de l'année représenté par une perle."
    >
      <div id="works-perle-background">
        <WorkHeader
          title={dict.getTrans("work_perle_title")}
          subTitle={dict.getTrans("work_perle_subtitle")}
          pic={perle}
          navLang={lang}
          hl="#FFFFFF"
          pageUrl="/works/perle"
        />
      </div>
      <WorkAbout
        title={dict.getTrans("perle_about_title")}
        p1={dict.getTrans("perle_about_p1")}
        p2={dict.getTrans("perle_about_p2")}
        logo={logo}
      />
      <div id="works-perle-perks-holder">
        <Perk
          title={dict.getTrans("perle_perk1_title")}
          image={perle2}
          text={dict.getTrans("perle_perk1_text")}
        />
        <Perk
          title={dict.getTrans("perle_perk2_title")}
          image={perle3}
          text={dict.getTrans("perle_perk2_text")}
        />
        <Perk
          title={dict.getTrans("perle_perk3_title")}
          image={perle1}
          text={dict.getTrans("perle_perk3_text")}
        />
      </div>
      <div id="works-tft-contact">
        <Contact lang="en" />
      </div>
      <Footer lang={lang} />
    </Layout>
  )
}
